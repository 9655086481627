import { toast } from 'react-toastify'
import { MockEnv } from '../../globals/constants'
import { GlobalUserContext } from '../../globals/globals'
import { logError, logWarning } from '../../util/browser'
import { setLocalStorage } from '../../util/storage'
import { StoryContainer, StoryContent, StoryId, StoryMetadata } from '../story/storycontainer'
import { PresetId, StoryPreset, AIModule } from '../story/storysettings'
import { User } from '../user/user'
import { UserSettings } from '../user/settings'
import { IndexedDBStorage } from './indexeddbstorage'
import { KeyStore } from './keystore/keystore'
import MockStorage from './mockstorage'
import { RemoteStorage } from './remotestorage'

export interface IStorage {
    user: User

    saveSettings(settings: UserSettings): Promise<void>
    getPresets(): Promise<Array<StoryPreset>>
    savePreset(preset: StoryPreset): Promise<PresetId>
    deletePreset(preset: StoryPreset): Promise<void>
    saveStory(
        story: StoryContainer,
        askOverwrite?: (other: StoryContainer) => Promise<boolean>
    ): Promise<StoryId>
    getStoryContent(id: string, additional?: string): Promise<StoryContent>
    getStoryContentRaw(id: string, additional?: string, forceLocal?: boolean): Promise<string>
    getStoryContents(): Promise<Map<StoryId, StoryContent>>
    getStoryContentsRaw(): Promise<string>
    getStoryMetadataRaw(): Promise<string>
    getStories(): Promise<Array<StoryMetadata>>
    deleteStory(storyMetadata: StoryMetadata): Promise<void>

    getModules(): Promise<Array<AIModule>>
    saveModule(aiModule: AIModule): Promise<string>
    deleteModule(aiModule: AIModule): Promise<void>

    getKeyStore(): Promise<KeyStore>
    saveKeyStore(force?: boolean): Promise<void>
    getStoryShelves(): Promise<Array<StoryMetadata>>
    saveStoryShelf(storyShelf: StoryMetadata): Promise<PresetId>
    deleteStoryShelf(storyShelf: StoryMetadata): Promise<void>
}

export function getStorage(user: User): Storage {
    return user.noAccount ? new NoAccountStorage(user) : new Storage(user)
}
const storageStatus = { localAvailable: true }
export function setLocalStorageUnavailable(): void {
    storageStatus.localAvailable = false
}
export function clearLocalStorageUnavailable(): void {
    storageStatus.localAvailable = true
}
export function getLocalStorageUnavailable(): boolean {
    return !storageStatus.localAvailable
}

export class Storage {
    user: User

    localstorage: IStorage | null
    remotestorage: IStorage

    constructor(user: User) {
        this.user = user
        if (!getLocalStorageUnavailable()) {
            this.localstorage = new IndexedDBStorage(user)
        }
        this.remotestorage = MockEnv ? new MockStorage() : new RemoteStorage(user)
    }
    saveSettings(settings: UserSettings): Promise<void> {
        return this.remotestorage.saveSettings(settings)
    }
    getPresets(): Promise<Array<StoryPreset>> {
        return this.remotestorage.getPresets()
    }
    savePreset(preset: StoryPreset): Promise<PresetId> {
        return this.remotestorage.savePreset(preset)
    }
    deletePreset(preset: StoryPreset): Promise<void> {
        return this.remotestorage.deletePreset(preset)
    }
    async getModules(): Promise<Array<AIModule>> {
        return await this.remotestorage.getModules()
    }
    saveModule(module: AIModule): Promise<PresetId> {
        return this.remotestorage.saveModule(module)
    }
    deleteModule(module: AIModule): Promise<void> {
        return this.remotestorage.deleteModule(module)
    }
    async getStories(): Promise<{ stories: Array<StoryMetadata>; extras: Array<StoryMetadata> }> {
        const extras: Array<StoryMetadata> = []
        let remoteStories = await this.remotestorage.getStories()
        // Go through remote stories and find any with matching IDs. This is something that should never
        // happen, but has happened for unknown reasons. Leave the one with the latest lastUpdatedAt
        // timestamp. Put any others in the extras list.
        const remoteMap = new Map<string, StoryMetadata>()
        for (const story of remoteStories) {
            const dupe = remoteMap.get(story.id)
            if (dupe) {
                if (story.lastUpdatedAt > dupe.lastUpdatedAt) {
                    extras.push(dupe)
                    remoteMap.set(story.id, story)
                } else {
                    extras.push(story)
                }
            } else {
                remoteMap.set(story.id, story)
            }
        }
        remoteStories = [...remoteMap.values()]

        const localStories = (await this.localstorage?.getStories()) ?? []
        let stories = [...localStories]
        const noLocalVersion = []
        for (const story of remoteStories) {
            const dupeIndex = stories.findIndex((dstory) => dstory.id === story.id)
            if (dupeIndex >= 0) {
                stories[dupeIndex].remote = true
                stories[dupeIndex].remoteId = story.remoteId
                stories[dupeIndex].remoteStoryId = story.remoteStoryId
                if (story.lastUpdatedAt >= stories[dupeIndex].lastUpdatedAt) {
                    stories[dupeIndex] = story
                }
            } else if (dupeIndex === -1) {
                noLocalVersion.push(story)
            }
        }
        stories = [...stories, ...noLocalVersion]
        stories = stories.sort((a, b) => b.lastUpdatedAt.valueOf() - a.lastUpdatedAt.valueOf())
        return { stories, extras }
    }
    async getStoryContents(): Promise<Array<StoryContainer>> {
        const { stories: storyMetas } = await this.getStories()

        let localStoryContents = null
        try {
            localStoryContents = (await this.localstorage?.getStoryContents()) ?? null
        } catch (error) {
            logError(error, false, 'loading local story content failed')
        }
        let remoteStoryContents = null
        try {
            remoteStoryContents = await this.remotestorage.getStoryContents()
        } catch (error) {
            logError(error, false, 'loading remote story content failed')
        }
        const stories = []
        for (const storyMetadata of storyMetas) {
            let storyContent: StoryContent | undefined = void 0

            if (
                remoteStoryContents &&
                storyMetadata.remoteStoryId !== undefined &&
                storyMetadata.remoteId !== undefined
            )
                storyContent = remoteStoryContents.get(storyMetadata.id)
            if (localStoryContents && !storyContent) storyContent = localStoryContents.get(storyMetadata.id)

            if (storyContent) stories.push(StoryContainer.bundle(storyMetadata, storyContent))
            else logWarning('story metadata with no content: ' + storyMetadata.id)
        }
        return stories
    }
    async getStoryContent(storyMetadata: StoryMetadata): Promise<StoryContent> {
        return storyMetadata.remoteStoryId !== undefined && storyMetadata.remoteId !== undefined
            ? this.remotestorage
                  .getStoryContent(storyMetadata.remoteStoryId, storyMetadata.remoteId)
                  .catch((error) => {
                      logWarning(error)
                      return (
                          this.localstorage?.getStoryContent(storyMetadata.id).catch(() => {
                              throw error
                          }) ?? error
                      )
                  })
            : this.localstorage?.getStoryContent(storyMetadata.id)
    }
    async getStoryContentRaw(
        storyMetadata: StoryMetadata,
        additional?: string,
        forceLocal?: boolean
    ): Promise<string> {
        return !forceLocal &&
            storyMetadata.remoteStoryId !== undefined &&
            storyMetadata.remoteId !== undefined
            ? this.remotestorage
                  .getStoryContentRaw(storyMetadata.remoteStoryId, storyMetadata.remoteId)
                  .catch((error) => {
                      logWarning(error)
                      return (
                          this.localstorage?.getStoryContent(storyMetadata.id).catch(() => {
                              throw error
                          }) ?? error
                      )
                  })
            : this.localstorage?.getStoryContentRaw(storyMetadata.id)
    }

    async getStoryRemoteContentsRaw(): Promise<string> {
        return this.remotestorage.getStoryContentsRaw()
    }

    async getStoryRemoteMetadataRaw(): Promise<string> {
        return this.remotestorage.getStoryMetadataRaw()
    }

    async getStoryContentsRaw(): Promise<string> {
        return this.localstorage?.getStoryContentsRaw() ?? 'no indexeddb'
    }

    async getStoryMetadataRaw(): Promise<string> {
        return this.localstorage?.getStoryMetadataRaw() ?? 'no indexeddb'
    }

    async saveStory(
        story: StoryContainer,
        remote?: boolean,
        askOverwrite?: (other: StoryContainer) => Promise<boolean>,
        forceRemoteSave?: boolean // Used for debugging purposes only
    ): Promise<StoryId> {
        if (forceRemoteSave) {
            const remoteResult = await this.remotestorage.saveStory(story, askOverwrite)
            return this.localstorage?.saveStory(story) ?? remoteResult
        }
        if (remote) {
            // eslint-disable-next-line unicorn/prefer-ternary
            if (!story.metadata.remote && GlobalUserContext.remoteStories.has(story.metadata.id)) {
                await this.localstorage?.saveStory(story).catch((error) => {
                    throw new Error(`Local save failed—${error?.message ?? error}`)
                })
                await this.remotestorage.deleteStory(story.metadata).catch((error) => {
                    throw new Error(`Remote delete failed—${error?.message ?? error}`)
                })
                await this.localstorage?.saveStory(story).catch((error) => {
                    throw new Error(`Local save failed—${error?.message ?? error}`)
                })
                return story.metadata.id
            } else {
                const remoteResult = await this.remotestorage
                    .saveStory(story, askOverwrite)
                    .catch((error) => {
                        throw new Error(`Remote save failed—${error?.message ?? error}`)
                    })
                return (
                    this.localstorage?.saveStory(story).catch((error) => {
                        throw new Error(`Local save failed—${error?.message ?? error}`)
                    }) ?? remoteResult
                )
            }
        } else {
            if (this.localstorage) {
                return this.localstorage.saveStory(story).catch((error) => {
                    throw new Error(`Local save failed—${error?.message ?? error}`)
                })
            } else {
                throw new Error('local storage is not available')
            }
        }
    }
    async deleteStory(storyMetadata: StoryMetadata): Promise<void> {
        this.remotestorage.deleteStory(storyMetadata)
        this.localstorage?.deleteStory(storyMetadata)
    }

    getKeyStore(): Promise<KeyStore> {
        return this.remotestorage.getKeyStore()
    }
    saveKeyStore(force?: boolean): Promise<void> {
        return this.remotestorage.saveKeyStore(force)
    }

    async getStoryShelves(): Promise<Array<StoryMetadata>> {
        return this.remotestorage.getStoryShelves()
    }
    async saveStoryShelf(storyShelf: StoryMetadata): Promise<PresetId> {
        return this.remotestorage.saveStoryShelf(storyShelf)
    }
    async deleteStoryShelf(storyShelf: StoryMetadata): Promise<void> {
        return this.remotestorage.deleteStoryShelf(storyShelf)
    }
}

export class NoAccountStorage extends Storage {
    saveSettings(settings: UserSettings): Promise<void> {
        setLocalStorage('noAccountSettings', JSON.stringify(settings))
        return Promise.resolve()
    }
    getPresets(): Promise<Array<StoryPreset>> {
        throw 'Not available for non-account users'
    }
    savePreset(): Promise<PresetId> {
        toast('Custom presets not available without an account.')
        throw 'Not available for non-account users'
    }
    deletePreset(): Promise<void> {
        throw 'Not available for non-account users'
    }
    getModules(): Promise<Array<AIModule>> {
        toast('Custom modules not available without an account.')
        throw 'Not available for non-account users'
    }
    saveModule(): Promise<PresetId> {
        throw 'Not available for non-account users'
    }
    deleteModule(): Promise<void> {
        throw 'Not available for non-account users'
    }
    async getStories(): Promise<{ stories: Array<StoryMetadata>; extras: Array<StoryMetadata> }> {
        const localStories = (await this.localstorage?.getStories()) ?? []
        let stories = [...localStories]
        stories = stories.sort((a, b) => b.lastUpdatedAt.valueOf() - a.lastUpdatedAt.valueOf())
        return { stories, extras: [] }
    }
    async getStoryContents(): Promise<Array<StoryContainer>> {
        const { stories: storyMetas } = await this.getStories()

        if (!this.localstorage) {
            throw new Error('local storage is not available')
        }
        const localStoryContents = await this.localstorage.getStoryContents()

        const stories = []
        for (const storyMetadata of storyMetas) {
            let storyContent

            if (!storyContent) storyContent = localStoryContents.get(storyMetadata.id)

            if (storyContent) stories.push(StoryContainer.bundle(storyMetadata, storyContent))
            else logWarning('story metadata with no content: ' + storyMetadata.id)
        }

        return stories
    }
    async getStoryContent(storyMetadata: StoryMetadata): Promise<StoryContent> {
        if (!this.localstorage) {
            throw new Error('local storage is not available')
        }
        return this.localstorage.getStoryContent(storyMetadata.id)
    }
    async saveStory(story: StoryContainer): Promise<StoryId> {
        if (!this.localstorage) {
            throw new Error('local storage is not available')
        }
        return this.localstorage.saveStory(story)
    }
    async deleteStory(storyMetadata: StoryMetadata): Promise<void> {
        if (!this.localstorage) {
            throw new Error('local storage is not available')
        }
        this.localstorage.deleteStory(storyMetadata)
    }

    getKeyStore(): Promise<KeyStore> {
        throw 'Not available for non-account users'
    }
    saveKeyStore(): Promise<void> {
        throw 'Not available for non-account users'
    }

    async getStoryShelves(): Promise<Array<StoryMetadata>> {
        throw 'Not available for non-account users'
    }
    async saveStoryShelf(): Promise<PresetId> {
        throw 'Not available for non-account users'
    }
    async deleteStoryShelf(): Promise<void> {
        throw 'Not available for non-account users'
    }
}
