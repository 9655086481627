import { Buffer } from 'buffer'
import { serialize } from 'serializr'
import { BackendUrl } from '../../globals/constants'
import { GlobalUserContext } from '../../globals/globals'
import { logError, logWarning } from '../../util/browser'
import { fetchWithTimeout } from '../../util/general'
import { formatErrorResponse, metadataDiffers, randomShortID, until } from '../../util/util'
import { StoryContainer, StoryContent, StoryId, StoryMetadata } from '../story/storycontainer'
import { PresetId, StoryPreset, AIModule } from '../story/storysettings'
import { User } from '../user/user'
import { UserSettings } from '../user/settings'
import { IStoryDto, KeyStore } from './keystore/keystore'
import { IStorage } from './storage'

export class RemoteStorage implements IStorage {
    user: User
    id = randomShortID()

    storySaveInProgress = new Set<StoryId>()

    constructor(user: User) {
        this.user = user
    }

    request(): RequestInit {
        return {
            mode: 'cors',
            cache: 'no-store',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.user.auth_token,
                'x-correlation-id': this.id,
                'x-initiated-at': new Date().toISOString(),
            },
        }
    }

    private refreshId() {
        this.id = randomShortID()
    }

    async getPresets(): Promise<Array<StoryPreset>> {
        try {
            this.refreshId()
            const request: RequestInit = {
                ...this.request(),
            }
            const url = BackendUrl.Presets
            request.method = 'GET'

            const response = await fetchWithTimeout(url, request, void 0, void 0, void 0, this.id)
            if (!response.ok) {
                throw await formatErrorResponse(response, void 0, this.id)
            }
            const json = await response.json()
            if (json?.statusCode && json?.statusCode !== 200) {
                throw new Error(json.message?.message || json.message || json.statusCode)
            }

            const presets = [] as StoryPreset[]
            if (json?.objects) {
                for (const preset of json.objects) {
                    try {
                        const newPreset = StoryPreset.deserialize(
                            Buffer.from(preset.data, 'base64').toString('utf8')
                        )
                        newPreset.remoteId = preset.id
                        presets.push(newPreset)
                    } catch (error) {
                        logError(error, true, 'error parsing preset')
                        continue
                    }
                }
            }
            return presets
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async savePreset(preset: StoryPreset): Promise<PresetId> {
        try {
            this.refreshId()
            const presetSaveRequest: RequestInit = {
                ...this.request(),
            }

            let contentUrl = BackendUrl.Presets
            if (preset.remoteId) {
                presetSaveRequest.method = 'PATCH'
                contentUrl = contentUrl + '/' + preset.remoteId
            } else {
                presetSaveRequest.method = 'PUT'
            }

            presetSaveRequest.body = JSON.stringify({
                data: Buffer.from(JSON.stringify(serialize(StoryPreset, preset))).toString('base64'),
                meta: '',
            })
            const response = await fetchWithTimeout(
                contentUrl,
                presetSaveRequest,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!response.ok) {
                throw await formatErrorResponse(response, void 0, this.id)
            }
            const json = await response.json()
            preset.remoteId = json.id
            return json.id
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async deletePreset(preset: StoryPreset): Promise<void> {
        try {
            this.refreshId()
            const presetDeleteRequest: RequestInit = {
                ...this.request(),
                method: 'DELETE',
            }
            // Delete Content-Type for DELETE requests
            if (presetDeleteRequest.headers) delete (presetDeleteRequest.headers as any)['Content-Type']

            const presetUrl = BackendUrl.Presets + '/' + preset.remoteId
            const presetResponse = await fetchWithTimeout(
                presetUrl,
                presetDeleteRequest,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!presetResponse.ok) {
                if (presetResponse.status === 404) {
                    logWarning(formatErrorResponse(presetResponse, void 0, this.id))
                } else {
                    throw await formatErrorResponse(presetResponse, void 0, this.id)
                }
            }
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async saveSettings(settings: UserSettings): Promise<void> {
        try {
            this.refreshId()
            const request: RequestInit = {
                ...this.request(),
            }

            const url = BackendUrl.ClientSettings
            request.method = 'PUT'

            request.body = JSON.stringify(settings)

            const response = await fetchWithTimeout(url, request, void 0, void 0, void 0, this.id)
            if (!response.ok) {
                throw await formatErrorResponse(response, void 0, this.id)
            }
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async getKeyStore(): Promise<KeyStore> {
        try {
            this.refreshId()
            const request: RequestInit = {
                ...this.request(),
            }
            const keystore = new KeyStore()
            const response = await fetchWithTimeout(
                BackendUrl.Keystore,
                request,
                void 0,
                void 0,
                void 0,
                this.id
            )
            const json = await response.json()
            keystore.load(json.keystore, this.user.encryption_key, json.changeIndex)
            return keystore
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async saveKeyStore(force: boolean = false): Promise<void> {
        try {
            this.refreshId()
            const request: RequestInit = {
                ...this.request(),
            }
            const url = BackendUrl.Keystore
            request.method = 'PUT'
            const body = {
                keystore: await GlobalUserContext.keystore.store(),
            } as any
            if (!force) {
                body.changeIndex = GlobalUserContext.keystore.changeIndex
            }
            request.body = JSON.stringify(body)
            const response = await fetchWithTimeout(url, request, void 0, void 0, void 0, this.id)
            if (!response.ok) {
                // conflict, keystore changeIndex invalid
                if (response.status === 409) {
                    const newKeyStore = await this.getKeyStore()
                    await GlobalUserContext.keystore.merge(newKeyStore)
                    GlobalUserContext.keystore.changeIndex = (newKeyStore.changeIndex ?? 0) + 1
                    this.saveKeyStore(true)
                } else {
                    throw await formatErrorResponse(response, void 0, this.id)
                }
            }
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async saveStory(
        story: StoryContainer,
        askOverwrite?: (other: StoryContainer) => Promise<boolean>
    ): Promise<string> {
        const storyId = story.metadata.id
        await until(() => !this.storySaveInProgress.has(storyId))
        this.storySaveInProgress.add(storyId)

        let result
        try {
            result = this._saveStory(story, askOverwrite)
        } finally {
            this.storySaveInProgress.delete(storyId)
        }
        return result
    }
    private async _saveStory(
        story: StoryContainer,
        askOverwrite?: (other: StoryContainer) => Promise<boolean>
    ): Promise<string> {
        try {
            this.refreshId()
            const contentSaveRequest: RequestInit = {
                ...this.request(),
            }

            // Save story content
            let contentUrl = BackendUrl.StoryContent
            if (
                story.metadata.remoteStoryId !== undefined &&
                GlobalUserContext.remoteStories.has(story.metadata.id)
            ) {
                contentSaveRequest.method = 'PATCH'
                contentUrl = contentUrl + '/' + story.metadata.remoteStoryId
            } else {
                contentSaveRequest.method = 'PUT'
            }

            let keyStoreChanged = false
            let dto = await GlobalUserContext.keystore.encryptStoryContent(
                story.content,
                story.metadata,
                async () => {
                    keyStoreChanged = true
                }
            )
            contentSaveRequest.body = JSON.stringify({
                data: dto.data,
                meta: dto.meta,
                changeIndex: story.content.changeIndex,
            })
            let response = await fetchWithTimeout(
                contentUrl,
                contentSaveRequest,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!response.ok) {
                if (response.status === 409 && story.metadata.remoteStoryId && story.metadata.remoteId) {
                    const newStoryContent = await this.getStoryContent(story.metadata.remoteStoryId)
                    const newStoryMetadata = await this.getStoryMetadata(story.metadata.remoteId)
                    const newStoryContainer = StoryContainer.bundle(newStoryMetadata, newStoryContent)
                    const overwrite = askOverwrite ? await askOverwrite(newStoryContainer) : true
                    if (overwrite) {
                        story.content.changeIndex = newStoryContent.changeIndex
                        story.metadata.changeIndex = newStoryMetadata.changeIndex
                        return this._saveStory(story, async () => true)
                    } else {
                        story.content = newStoryContent
                        story.metadata = newStoryMetadata
                        GlobalUserContext.stories.set(story.metadata.id, story.metadata)
                        GlobalUserContext.storyContentCache.set(story.metadata.id, story.content)
                        return story.metadata.id
                    }
                } else {
                    throw await formatErrorResponse(response, void 0, this.id)
                }
            }
            let json = await response.json()

            story.metadata.remoteStoryId = json.id
            story.content.changeIndex = json.changeIndex

            // Save story metadata, must be after to properly save story remote id
            this.refreshId()
            const metadataSaveRequest: RequestInit = {
                ...this.request(),
            }

            let url = BackendUrl.Stories
            if (
                story.metadata.remoteId !== undefined &&
                GlobalUserContext.remoteStories.has(story.metadata.id)
            ) {
                metadataSaveRequest.method = 'PATCH'
                url = url + '/' + story.metadata.remoteId
            } else {
                metadataSaveRequest.method = 'PUT'
            }
            dto = await GlobalUserContext.keystore.encryptStoryMetadata(story.metadata, async () => {
                keyStoreChanged = true
            })
            metadataSaveRequest.body = JSON.stringify({
                data: dto.data,
                meta: dto.meta,
                changeIndex: story.metadata.changeIndex,
            })
            response = await fetchWithTimeout(url, metadataSaveRequest, void 0, void 0, void 0, this.id)
            if (!response.ok) {
                if (response.status === 409 && story.metadata.remoteStoryId && story.metadata.remoteId) {
                    const newStoryContent = await this.getStoryContent(story.metadata.remoteStoryId)
                    const newStoryMetadata = await this.getStoryMetadata(story.metadata.remoteId)
                    const newStoryContainer = StoryContainer.bundle(newStoryMetadata, newStoryContent)
                    const overwrite =
                        !metadataDiffers(story.metadata, newStoryMetadata) ||
                        (askOverwrite ? await askOverwrite(newStoryContainer) : true)
                    if (overwrite) {
                        story.content.changeIndex = newStoryContent.changeIndex
                        story.metadata.changeIndex = newStoryMetadata.changeIndex
                        return this._saveStory(story, askOverwrite)
                    } else {
                        story.content = newStoryContent
                        story.metadata = newStoryMetadata
                        GlobalUserContext.stories.set(story.metadata.id, story.metadata)
                        GlobalUserContext.storyContentCache.set(story.metadata.id, story.content)
                        return story.metadata.id
                    }
                } else {
                    throw await formatErrorResponse(response, void 0, this.id)
                }
            }
            json = await response.json()

            story.metadata.remoteId = json.id
            story.metadata.changeIndex = json.changeIndex

            if (keyStoreChanged) {
                await this.saveKeyStore()
            }
            if (!GlobalUserContext.remoteStories.has(story.metadata.id)) {
                GlobalUserContext.remoteStories.add(story.metadata.id)
            }
            return story.metadata.id
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async getStories(): Promise<Array<StoryMetadata>> {
        try {
            this.refreshId()
            const request: RequestInit = {
                ...this.request(),
                method: 'GET',
            }
            const response = await fetchWithTimeout(
                BackendUrl.Stories,
                request,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!response.ok) {
                throw await formatErrorResponse(response, void 0, this.id)
            }
            const json = await response.json()
            if (json?.statusCode && json?.statusCode !== 200) {
                throw new Error(json.message?.message || json.message || json.statusCode)
            }
            const stories = [] as StoryMetadata[]
            if (json?.objects) {
                const errors = [] as { error: any; id: string }[]
                for (const storyMetadata of json.objects) {
                    try {
                        const decrypted: StoryMetadata | null =
                            await GlobalUserContext.keystore.decryptStoryMetadata(storyMetadata)
                        if (!decrypted) continue
                        decrypted.remote = true
                        decrypted.remoteId = storyMetadata.id
                        decrypted.changeIndex = storyMetadata.changeIndex
                        stories.push(decrypted)
                        GlobalUserContext.remoteStories.add(decrypted.id)
                    } catch (error) {
                        errors.push({ error, id: (storyMetadata as IStoryDto).id ?? '' })
                        continue
                    }
                }
                if (errors.length > 0) {
                    const groupedErrors = errors.reduce((acc, cur) => {
                        const index = cur.error.message ? `${cur.error.message}` : `${cur.error}`
                        return {
                            ...acc,
                            [index]: [...(acc[index] ?? []), cur],
                        }
                    }, {} as Record<string, { error: any; id: string }[]>)
                    for (const [message, errors] of Object.entries(groupedErrors)) {
                        logWarning(
                            message,
                            false,
                            'failed unlocking stories ' +
                                errors
                                    .map(
                                        (error) =>
                                            `${error.id}${error.error.id ? ' (' + error.error.id + ')' : ''}`
                                    )
                                    .join(', ')
                        )
                    }
                }
            }
            return stories
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async getStoryMetadata(remoteId: string): Promise<StoryMetadata> {
        try {
            this.refreshId()
            const request: RequestInit = {
                ...this.request(),
                method: 'GET',
            }
            const response = await fetchWithTimeout(
                BackendUrl.Stories + '/' + remoteId,
                request,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!response.ok) {
                throw await formatErrorResponse(response, void 0, this.id)
            }
            const json = await response.json()
            if (json?.statusCode && json?.statusCode !== 200) {
                throw new Error(json.message?.message || json.message || json.statusCode)
            }

            const decrypted: StoryMetadata | null = await GlobalUserContext.keystore.decryptStoryMetadata(
                json
            )
            if (!decrypted) {
                throw 'Story content could not be decrypted'
            }
            decrypted.changeIndex = json.changeIndex
            decrypted.remoteId = json.id
            decrypted.remote = true
            return decrypted
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    /// load all stories without storing them in the cache
    async getStoryContents(): Promise<Map<StoryId, StoryContent>> {
        try {
            this.refreshId()
            const request: RequestInit = {
                ...this.request(),
                method: 'GET',
            }
            const response = await fetchWithTimeout(
                BackendUrl.StoryContent,
                request,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!response.ok) {
                throw await formatErrorResponse(response, void 0, this.id)
            }
            const json = await response.json()
            if (json?.statusCode && json?.statusCode !== 200) {
                throw new Error(json.message?.message || json.message || json.statusCode)
            }
            const stories = new Map()
            if (json?.objects) {
                for (const story of json.objects) {
                    try {
                        const decrypted = (await GlobalUserContext.keystore.decryptStoryContent(
                            story
                        )) as StoryContent | null
                        if (!decrypted) continue
                        decrypted.changeIndex = story.changeIndex
                        stories.set(story.meta, decrypted)
                    } catch (error) {
                        logError(error, false, 'error unlocking story')
                        continue
                    }
                }
            }
            return stories
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async getStoryContentsRaw(): Promise<string> {
        try {
            this.refreshId()
            const request: RequestInit = {
                ...this.request(),
                method: 'GET',
            }
            const response = await fetchWithTimeout(
                BackendUrl.StoryContent,
                request,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!response.ok) {
                throw await formatErrorResponse(response, void 0, this.id)
            }

            return response.text()
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async getStoryMetadataRaw(): Promise<string> {
        try {
            this.refreshId()
            const request: RequestInit = {
                ...this.request(),
                method: 'GET',
            }
            const response = await fetchWithTimeout(
                BackendUrl.Stories,
                request,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!response.ok) {
                throw await formatErrorResponse(response, void 0, this.id)
            }
            return response.text()
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async getStoryContent(remoteStoryId: string, remoteId?: string): Promise<StoryContent> {
        try {
            this.refreshId()
            const request: RequestInit = {
                ...this.request(),
                method: 'GET',
            }
            const response = await fetchWithTimeout(
                BackendUrl.StoryContent + '/' + remoteStoryId,
                request,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!response.ok) {
                throw await formatErrorResponse(response, void 0, this.id)
            }
            const json = await response.json()
            if (json?.statusCode && json?.statusCode !== 200) {
                throw new Error(json.message?.message || json.message || json.statusCode)
            }

            json.id = remoteId
            const decrypted = (await GlobalUserContext.keystore.decryptStoryContent(
                json
            )) as StoryContent | null
            if (!decrypted) {
                throw 'Story content could not be decrypted'
            }
            decrypted.changeIndex = json.changeIndex
            return decrypted
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async getStoryContentRaw(remoteStoryId: string, remoteId?: string): Promise<string> {
        try {
            this.refreshId()
            const request: RequestInit = {
                ...this.request(),
                method: 'GET',
            }
            const response = await fetchWithTimeout(
                BackendUrl.StoryContent + '/' + remoteStoryId,
                request,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!response.ok) {
                throw await formatErrorResponse(response, void 0, this.id)
            }
            const json = await response.json()
            if (json?.statusCode && json?.statusCode !== 200) {
                throw new Error(json.message?.message || json.message || json.statusCode)
            }

            json.id = remoteId
            const decrypted = (await GlobalUserContext.keystore.decryptStoryContent(json, true)) as
                | string
                | null
            if (!decrypted) {
                throw 'Story content could not be decrypted'
            }
            return decrypted
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async deleteStory(metadata: StoryMetadata): Promise<void> {
        try {
            this.refreshId()
            if (!GlobalUserContext.remoteStories.has(metadata.id)) {
                return
            }

            const contentDeleteRequest: RequestInit = {
                ...this.request(),
                method: 'DELETE',
            }
            // Delete Content-Type for DELETE requests
            if (contentDeleteRequest.headers) delete (contentDeleteRequest.headers as any)['Content-Type']
            const contentUrl = BackendUrl.StoryContent + '/' + metadata.remoteStoryId
            const contentResponse = await fetchWithTimeout(
                contentUrl,
                contentDeleteRequest,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!contentResponse.ok) {
                if (contentResponse.status === 404) {
                    logWarning(formatErrorResponse(contentResponse, void 0, this.id))
                } else {
                    throw await formatErrorResponse(contentResponse, void 0, this.id)
                }
            }

            this.refreshId()
            const metadataDeleteRequest: RequestInit = {
                ...this.request(),
                method: 'DELETE',
            }
            // Delete Content-Type for DELETE requests
            if (metadataDeleteRequest.headers) delete (metadataDeleteRequest.headers as any)['Content-Type']
            const metadataUrl = BackendUrl.Stories + '/' + metadata.remoteId
            const metadataResponse = await fetchWithTimeout(
                metadataUrl,
                metadataDeleteRequest,
                void 0,
                void 0,
                void 0,
                this.id
            )
            if (!metadataResponse.ok) {
                throw await formatErrorResponse(metadataResponse, void 0, this.id)
            }

            GlobalUserContext.remoteStories.delete(metadata.id)
            metadata.remote = false
            metadata.remoteId = undefined
            metadata.remoteStoryId = undefined
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async getModules(): Promise<Array<AIModule>> {
        try {
            this.refreshId()
            const request: RequestInit = {
                ...this.request(),
            }
            const url = BackendUrl.AIModules
            request.method = 'GET'

            const response = await fetchWithTimeout(url, request, void 0, void 0, void 0, this.id)
            if (!response.ok) {
                throw await formatErrorResponse(response, void 0, this.id)
            }
            const json = await response.json()
            if (json?.statusCode && json?.statusCode !== 200) {
                throw new Error(json.message?.message || json.message || json.statusCode)
            }

            const modules = [] as AIModule[]
            if (json?.objects) {
                const errors = [] as { error: any; id: string }[]
                for (const encryptedModule of json.objects) {
                    try {
                        const newModule = await GlobalUserContext.keystore.decryptModule(encryptedModule)
                        newModule.remoteId = encryptedModule.id
                        modules.push(newModule)
                    } catch (error: any) {
                        errors.push({ error, id: (encryptedModule as IStoryDto).id ?? '' })
                        continue
                    }
                }
                if (errors.length > 0) {
                    const groupedErrors = errors.reduce((acc, cur) => {
                        const index = cur.error.message ? `${cur.error.message}` : `${cur.error}`
                        return {
                            ...acc,
                            [index]: [...(acc[index] ?? []), cur],
                        }
                    }, {} as Record<string, { error: any; id: string }[]>)
                    for (const [message, errors] of Object.entries(groupedErrors)) {
                        logWarning(
                            message,
                            false,
                            'failed parsing modules ' +
                                errors
                                    .map(
                                        (error) =>
                                            `${error.id}${error.error.id ? ' (' + error.error.id + ')' : ''}`
                                    )
                                    .join(', ')
                        )
                    }
                }
            }

            return modules
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async saveModule(aiModule: AIModule): Promise<PresetId> {
        try {
            this.refreshId()
            const moduleSaveRequest: RequestInit = {
                ...this.request(),
            }

            let url = BackendUrl.AIModules
            if (aiModule.remoteId) {
                moduleSaveRequest.method = 'PATCH'
                url = url + '/' + aiModule.remoteId
            } else {
                moduleSaveRequest.method = 'PUT'
            }

            let keyStoreChanged = false
            const dto = await GlobalUserContext.keystore.encryptModule(aiModule, async () => {
                keyStoreChanged = true
            })

            moduleSaveRequest.body = JSON.stringify({
                data: dto.data,
                meta: dto.meta,
            })
            const response = await fetchWithTimeout(url, moduleSaveRequest, void 0, void 0, void 0, this.id)
            if (!response.ok) {
                throw await formatErrorResponse(response, void 0, this.id)
            }
            const json = await response.json()
            aiModule.remoteId = json.id

            if (keyStoreChanged) {
                await this.saveKeyStore()
            }

            return json.id
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async deleteModule(aiModule: AIModule): Promise<void> {
        try {
            this.refreshId()
            const request: RequestInit = {
                ...this.request(),
                method: 'DELETE',
            }
            // Delete Content-Type for DELETE requests
            if (request.headers) delete (request.headers as any)['Content-Type']
            const url = BackendUrl.AIModules + '/' + aiModule.remoteId
            const response = await fetchWithTimeout(url, request, void 0, void 0, void 0, this.id)
            if (!response.ok) {
                throw await formatErrorResponse(response, void 0, this.id)
            }
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async getStoryShelves(): Promise<Array<StoryMetadata>> {
        try {
            const request: RequestInit = {
                ...this.request(),
                method: 'GET',
            }
            const url = BackendUrl.StoryShelves

            const response = await fetchWithTimeout(url, request, void 0, void 0, void 0, this.id)
            if (!response.ok) {
                if (response.status === 404) {
                    logWarning(formatErrorResponse(response, void 0, this.id))
                } else {
                    throw await formatErrorResponse(response, void 0, this.id)
                }
            }

            const json = await response.json()
            if (json?.statusCode && json?.statusCode !== 200) {
                throw new Error(json.message?.message || json.message || json.statusCode)
            }

            const shelves = [] as StoryMetadata[]
            if (json?.objects) {
                for (const shelf of json.objects) {
                    try {
                        const newShelf = StoryMetadata.deserialize(
                            Buffer.from(shelf.data, 'base64').toString('utf8')
                        )
                        newShelf.remoteId = shelf.id
                        shelves.push(newShelf)
                    } catch (error) {
                        logError(error, true, 'error parsing shelf')
                        continue
                    }
                }
            }

            return shelves
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async saveStoryShelf(storyShelf: StoryMetadata): Promise<PresetId> {
        try {
            this.refreshId()
            const shelfSaveRequest: RequestInit = {
                ...this.request(),
            }
            storyShelf.lastSavedAt = new Date()
            storyShelf.lastUpdatedAt = new Date()

            let url = BackendUrl.StoryShelves
            if (storyShelf.remoteId) {
                shelfSaveRequest.method = 'PATCH'
                url = url + '/' + storyShelf.remoteId
            } else {
                shelfSaveRequest.method = 'PUT'
            }

            shelfSaveRequest.body = JSON.stringify({
                data: Buffer.from(storyShelf.serialize()).toString('base64'),
                meta: storyShelf.id,
            })
            const response = await fetchWithTimeout(url, shelfSaveRequest, void 0, void 0, void 0, this.id)
            if (!response.ok) {
                throw await formatErrorResponse(response, void 0, this.id)
            }
            const json = await response.json()
            storyShelf.remoteId = json.id

            return json.id
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }

    async deleteStoryShelf(storyShelf: StoryMetadata): Promise<void> {
        try {
            this.refreshId()
            const request: RequestInit = {
                ...this.request(),
                method: 'DELETE',
            }
            // Delete Content-Type for DELETE requests
            if (request.headers) delete (request.headers as any)['Content-Type']

            const url = BackendUrl.StoryShelves + '/' + storyShelf.remoteId
            const response = await fetchWithTimeout(url, request, void 0, void 0, void 0, this.id)
            if (!response.ok) {
                if (response.status === 404) {
                    logWarning(formatErrorResponse(response, void 0, this.id))
                } else {
                    throw await formatErrorResponse(response, void 0, this.id)
                }
            }
        } catch (error: any) {
            // Add correlation id to error
            throw new Error(`[${this.id}] ${error?.message ?? error}`)
        }
    }
}
